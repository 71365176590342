<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" dayDateWithBackBtn="true" customer="true" />

    <v-row>
      <v-col md="8">
        <h1>PROJECT FILES</h1>
      </v-col>

      <v-col md="4" class="text-right">
        <v-btn
          v-if="canpost === 'true'"
          color="primary"
          dark
          @click="dialog = true"
          >ADD File
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1 cursor-pointer"
    >
      <template v-slot:item.openFile="{}">
        <a :src="url"></a>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-container>
          <div class="popup-wrap">
            <div class="d-flex justify-space-between">
              <div class="popup-title">
                <h6>Add File</h6>
              </div>

              <v-btn text @click.native="dialog = false">
                <v-img
                  src="../../assets/close-icon.png"
                  alt="close-icon"
                ></v-img>
              </v-btn>
            </div>

            <div class="popup-wrap-inner step-one">
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-row class="px-5">
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12">
                          <input type="file" ref="" accept="*/*" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                            label="Type"
                            required
                            :items="fileTypeValue"
                            v-model="addFile.type"
                          >
                          </v-select>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Comments"
                            required
                            outlined
                            v-model="addFile.comments"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <div class="text-right">
                            <v-btn color="primary" dark @click="saveFormData">
                              Add
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "TodayDocuments",
  components: { CustomerInfo },
  data() {
    return {
      componentURL: "",
      canpost: localStorage.getItem("canpost"),
      dialog: false,
      valid: false,
      url: null,
      headers: [
        { text: "Type", value: "type" },
        { text: "Uploaded By", value: "uploadedBy" },
        { text: "Comments", value: "comments" },
        { text: "Open File", value: "openFile" },
      ],
      items: [
        {
          type: "Engineer",
          uploadedBy: "StephanieF",
          comments: "Engineering drawing ",
          openFile: "",
        },
        {
          type: "Drawing",
          uploadedBy: "BillyK",
          comments: "Detail Drawing from Evaluator ",
          openFile: "",
        },
        {
          type: "Permits",
          uploadedBy: "StephanieF",
          comments: "Permits from Gladstone MO ",
          openFile: "",
        },
      ],
      fileTypeValue: ["PDF1", "PDF2", "PDF3"],
      addFile: {
        type: "",
        uploadedBy: "Carlos",
        comments: "",
        openFile: "",
      },
    };
  },
  methods: {
    saveFormData() {
      if (this.$refs.form.validate()) {
        this.items.push(this.addFile);
        this.dialog = false;
        this.addFile = {
          type: "",
          uploadedBy: "Carlos",
          comments: "",
          openFile: "",
        };
      }
    },
  },
};
</script>

<style scoped>
.popup-title {
  padding: 0px 18px !important;
  text-align: center;
}

.popup-wrap {
  padding: 20px 0;
}
</style>
